import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";

import { updateMetaTags } from "Utility/Util";
import OrderForm from "Components/pages/popup";

import BGcustomize from "Assets/Image/Customize/bg-customize.jpg";
import Tailored from "Assets/Image/Customize/Tailored.jpg";
import Modification from "Assets/Image/Customize/Modification.jpg";
import Craftsmanship from "Assets/Image/Customize/Craftsmanship.jpg";
// import Delivery from "Assets/Image/Customize/Delivery.jpg";
import Delivery from "Assets/Image/Customize/Delivery1.png";

function Customization() {
  useEffect(() => {
    updateMetaTags({
      title:
        "Custom Diamond Shapes and Cuts - Tailored to Your Vision | Arjiv Exports",
      description:
        "Discover how Arjiv Exports customizes diamond shapes and cuts to match your unique style and preferences. From brilliant to fancy cuts, we offer tailored solutions for your perfect diamond.",
      keywords:
        "custom diamond cuts, custom diamond order, personalized diamond shapes, diamond customization, diamond craftsmanship, bespoke diamonds, tailored diamond designs, diamond cutting services, unique diamond shapes, Arjiv Exports custom diamonds, fine diamond cuts, fancy diamond shapes, custom jewelry diamonds",
      canonical: "https://www.arjivexports.com/customization",
    });
  }, []);

  return (
    <div className="main-content pt-0">
      <div className="first-banner">
        <div className="bgimage-handler">
          <img
            src={BGcustomize}
            alt="Customize diamonds"
            className="bgimage w-100"
          />
          <OrderForm />
        </div>
        <div className="bgtext-handler">
          <p className="bgtext">
            ARJIV EXPORTS manufactures Diamonds of all Shapes, Size, Color,
            Clarity and Measurements, perfectly suited for your Jewellery.
          </p>
        </div>
      </div>
      <div className="py-2 mx-lg-4 mx-2 px-lg-4 px-0">
        <div className="imagesection container-fluid px-0">
          <div className="image-box dark-area py-3">
            <Row xs="1" sm="1" md="2">
              <Col className="cust p-2" lg={7} md={5} sm={12}>
                <img src={Tailored} alt="Tailored to your vision" />
              </Col>
              <Col className="cust p-2" lg={5} md={7} sm={12}>
                <Card body className="py-5 ">
                  <div className="custtext align-items-start p-2 p-lg-4">
                    <h3 className="pb-3 text-uppercase fs-2">
                      Tailored to your vision
                    </h3>
                    <p className="fs-5">
                      Whether you’re looking for a classic shape or something
                      truly one-of-a-kind, we offer a range of custom diamond
                      shapes, sizes, and designs to match your exact
                      specifications. From elegant cuts to intricate and unique
                      designs, our craftsmen are experts at bringing your vision
                      to life.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row
              className="flex-lg-row flex-md-row flex-column-reverse"
              xs="1"
              sm="1"
              md="2"
            >
              <Col className="cust p-2" lg={5} md={7} sm={12}>
                <Card body className="py-5 ">
                  <div className="custtext align-items-start p-2 p-lg-4">
                    <h3 className="pb-3 text-uppercase fs-2">Modification</h3>
                    <p className="fs-5">
                      Whether you’re modifying an existing diamond or creating
                      something from scratch, we provide a wide range of shape
                      modifications to suit your taste. Want to transform a
                      traditional cut into a more modern shape? We can make it
                      happen with precision and care.
                    </p>
                  </div>
                </Card>
              </Col>
              <Col className="cust p-2" lg={7} md={5} sm={12}>
                <img src={Modification} alt="Modification" />
              </Col>
            </Row>
            <Row xs="1" sm="1" md="2">
              <Col className="cust p-2" lg={7} md={5} sm={12}>
                <img src={Craftsmanship} alt="Precision Craftsmanship" />
              </Col>
              <Col className="cust p-2" lg={5} md={7} sm={12}>
                <Card body className="py-5 ">
                  <div className="custtext align-items-start p-2 p-lg-4">
                    <h3 className="pb-3 text-uppercase fs-2">
                      Precision Craftsmanship
                    </h3>
                    <p className="fs-5">
                      We use the latest diamond polishing technology, combined
                      with expert craftsmanship, to create a perfect cut that
                      enhances the brilliance and beauty of your diamond. Each
                      diamond is polished to exacting standards to meet your
                      design needs and exceed your expectations.
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row
              className="flex-lg-row flex-md-row flex-column-reverse"
              xs="1"
              sm="1"
              md="2"
            >
              <Col className="cust p-2" lg={5} md={7} sm={12}>
                <Card body className="py-5 ">
                  <div className="custtext align-items-start p-2 p-lg-4">
                    <h3 className="pb-3 text-uppercase fs-2">Delivery</h3>
                    <p className="fs-5">
                      After our meticulous polishing and quality assurance
                      process, your custom diamond is ready to be delivered to
                      you, polished to perfection.
                    </p>
                  </div>
                </Card>
              </Col>
              <Col className="cust p-2" lg={7} md={5} sm={12}>
                <img src={Delivery} alt="Delivery" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customization;
