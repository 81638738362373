import React, { Component } from "react";

import Modal from "react-awesome-modal";
import OrderForm2 from "Components/pages/customorder";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "./popup.css";

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleViewForm() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  render() {
    return (
      <section>
        <div id="arjiv-creation" className="arjiv-creation1">
          <p style={{ margin: "0px" }}>Arjiv</p>
          <p style={{ margin: "0px" }}> Creation</p>
        </div>
        <input
          type="button"
          value="Customize Your Diamonds"
          onClick={() => this.handleViewForm()}
          className="btn btn-outline-light bg-image-text"
        />
        <Modal
          className="modelcheck overflow-hidden"
          visible={this.state.visible}
          width="80%"
          effect="fadeInDown"
          onClickAway={() => this.handleViewForm()}
        >
          <div className="model-content">
            <div className="modelheader p-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0">
                Send Your Demand
              </h5>
              <IoIosCloseCircleOutline
                className="pointer"
                onClick={() => this.handleViewForm()}
              />
            </div>

            <div className="modelbody">
              <OrderForm2 props={this.handleViewForm} />
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}

export default OrderForm;
