import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Collapse, Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from "reactstrap";

import Events from "Components/pages/events";
import Journey from "Components/pages/journey";
import Policies from "Components/pages/policies";
import Contactus from "Components/pages/contact_us";
import Customization from "Components/pages/customization";

import logoWhite from "Assets/Image/arjiv-logo.png";

// SiteMap URL
import SiteMap from "Components/pages/xml";

import Home from "Components/pages/home";
import BlogList from "Components/pages/Blogs";
import GridBanner from "Components/GridBanner";
import WhatWeDO from "Components/pages/whatwedo";
import Rjcpolicy from "Components/pages/rjcpolicy";
import Dashboard from "Components/pages/Dashboard";
import AppPolicy from "Components/pages/app_policy";
import OldCut from "Components/discoverpage/OldCut";
import GradingSystem from "Components/GradingSystem";
import Provenance from "Components/pages/provenance";
import StepCut from "Components/discoverpage/StepCut";
import RoseCut from "Components/discoverpage/RoseCut";
import SideStone from "Components/discoverpage/SideStone";
import Exquisite from "Components/discoverpage/Exquisite";
import FancyColor from "Components/discoverpage/FancyColor";
import BlogDetail from "Components/pages/Blogs/BlogDetails";
import BriliantCut from "Components/discoverpage/BriliantCut";
import NotFound from "Components/NotFound";

import { FaUserCircle } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdCloseCircleOutline } from "react-icons/io";

import "./style.css";
import Milestone from "./pages/Milestone";

const HeaderPart = () => {
  const [isOpen, setIsOpen] = useState(false);
  let isFixed = false;

  const handlePdfDownload = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/Web_Ethical_Business_Policies.pdf`;
    window.open(pdfUrl, '_blank');
  };

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Router>
      {/* <Navbar expand="lg" center className={`nav-1 header d-flex px-3'}`}> */}
    <Navbar expand="lg" center className={`nav-1 header d-flex px-3 ${isFixed ? 'fixed-header' : ''}`}>
      <div className="d-lg-none d-block">
        <a href="/" className="arjivhead">
          <img src={logoWhite} width={40} height={40} alt="Arjiv Exports" />
        </a>
      </div>
      <RxHamburgerMenu onClick={toggle} width={50} height={50} className="toggle-menu fs-1 d-lg-none d-block d-inline-block" />
      <Collapse isOpen={isOpen} navbar className="justify-content-between">
        <div className="d-lg-block d-none">
          <a href="/" className="arjivhead">
            <img src={logoWhite} className="mx-3" width={60} alt="Arjiv Exports" />
          </a>
        </div>
        <Nav expand="lg" className="ms-auto align-items-center position-relative" navbar>
          <IoMdCloseCircleOutline onClick={toggle} fill="#303030" height={40} width={40} className="close-sidebar-btn ml-auto fs-1 position-absolute top-0 end-0 d-lg-none d-block" />
          <NavItem><NavLink href="/" className="navLink mt-5 mt-lg-0">home</NavLink></NavItem>
          <NavItem><NavLink href="/events" className="navLink">events</NavLink></NavItem>
          <NavItem><NavLink href="/journey" className="navLink">the journey</NavLink></NavItem>
          <NavItem><NavLink href="/customization" className="navLink">customization</NavLink></NavItem>
          <NavItem><NavLink href="/whatwedo" className="navLink">What We Do</NavLink></NavItem>
          <NavItem><NavLink href="/grading" className="navLink">grading</NavLink></NavItem>
          <UncontrolledDropdown nav inNavbar className="ddown">
            <DropdownToggle nav caret className="navLink">compliances</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem target="_blank" href="/policies" className="dropdown-item">policies - supply chain</DropdownItem>
              <DropdownItem target="_blank" href="/rjcpolicy" className="dropdown-item">RJC policy</DropdownItem>
              <DropdownItem className="dropdown-item" onClick={handlePdfDownload}>Web Ethical Business Policies</DropdownItem>
              <DropdownItem target="_blank" href="/provenance" className="dropdown-item">provenance claim</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem><NavLink href="/contact_us" className="navLink">contact us</NavLink></NavItem>
          <Nav className="d-flex align-items-center gap-2 flex-nowrap ml-lg-5 nav-item">
            <Button href="https://trade.arjivexports.com" className="mb-0 bg-transparent border-0 navLink nav-link d-flex align-items-center" style={{ gap: 5 }} target="_blank"><FaUserCircle className="fs-3" width={50}/>Login</Button>
            {/* <UncontrolledDropdown nav inNavbar className="ddown loginbtn mb-0 bg-white text-black btn btn-secondary px-1">
              <DropdownToggle nav caret className="socialmediatogle py-0" aria-label="Social Contacts">
                <AiOutlineShareAlt aria-label="Social Contacts" />
              </DropdownToggle>
              <DropdownMenu right className="dropdownmenusocial rounded overflow-hidden" aria-label="Socials">
                <DropdownItem className="dropdown-item-social"><NavLink target="_" href="https://www.facebook.com/arjivexportsnyc"><FaFacebookF /></NavLink></DropdownItem>
                <DropdownItem className="dropdown-item-social"><NavLink target="_" href="https://www.instagram.com/arjivexportsnyc"><FaInstagram /></NavLink></DropdownItem>
                <DropdownItem className="dropdown-item-social"><NavLink target="_blank" href="skype:live:.cid.5f5ec33ba48feb87?chat"><FaSkype /></NavLink></DropdownItem>
                <DropdownItem className="dropdown-item-social"><NavLink target="_" href="https://www.linkedin.com/in/arjiv-exports-a38bbb1b6"><FaLinkedinIn /></NavLink></DropdownItem>
                <DropdownItem className="dropdown-item-social"><NavLink target="_" href="https://api.whatsapp.com/send?phone=+917405793333&text=I%27m%20Interested%20in%20buying%20Diamonds%20from%20you"><FaWhatsapp /></NavLink></DropdownItem>
                <DropdownItem className="dropdown-item-social"><NavLink target="_" href="https://u.wechat.com/IEaKFeEc_-6I4QnjDR5qCQo"><AiFillWechat /></NavLink></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Nav>
        </Nav>
      </Collapse>
    </Navbar>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route
          path="/contact_us"
          component={Contactus}
          title="Contact Us Page"
        />
        <Route
          path="/customization"
          component={Customization}
          title="Contact Us Page"
        />
        <Route path="/events" component={Events} />
        <Route path="/journey" component={Journey} />
        <Route path="/policies" component={Policies} />
        <Route path="/provenance" component={Provenance} />
        <Route path="/rjcpolicy" component={Rjcpolicy} />
        <Route path="/briliantcut" component={BriliantCut} />
        <Route path="/oldcut" component={OldCut} />
        <Route path="/rosecut" component={RoseCut} />
        <Route path="/sidestone" component={SideStone} />
        <Route path="/fancycolor" component={FancyColor} />
        <Route path="/stepcut" component={StepCut} />
        <Route path="/whatwedo" component={WhatWeDO} />
        <Route path="/exquisite" component={Exquisite} />
        <Route path="/sitemap" component={SiteMap} />
        <Route path="/app_policy" component={AppPolicy} />
        <Route path="/grading" component={GradingSystem} />
        <Route path="/home" component={Dashboard} />
        <Route path="/grid" component={GridBanner} />
        <Route path="/blogs" component={BlogList} exact />
        <Route path="/blogs/:title" component={BlogDetail} />
        <Route path="/milestone" component={Milestone} exact /> {/*  // */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default HeaderPart;
