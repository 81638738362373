import React, { useEffect, useRef, useState } from "react";
import { updateMetaTags } from "Utility/Util";
import GridBanner from "Components/GridBanner";
import Planning from "Assets/Video/planning2.mp4";
import Marking from "Assets/Video/marking-grid.mp4";
import Sawing from "Assets/Video/sawing-grid.mp4";
import Bruting from "Assets/Video/Bruting.mp4";
import Polishing from "Assets/Video/Polishing.mp4";
import Grading from "Assets/Video/grading-grid.mp4";
import sorting from "Assets/Image/common/Sorting.jpg";
import { Col, Container, Row } from "reactstrap";

function WhatWeDo() {
  const processes = [
    {
      id: "sorting",
      title: "Sorting",
      img: sorting,
      descriptions: [
        "Our expert team carefully evaluates each rough diamond, measuring and assessing its characteristics. We separate exceptional stones for our Flawless collection and direct other quality diamonds to specialized processing. Each stone is individually evaluated for size, clarity, and potential value to determine its optimal manufacturing path.",
      ],
    },
    {
      id: "planning",
      title: "Planning",
      video: Planning,
      descriptions: [
        "Using advanced 3D scanning and modeling technology, our planners analyze each rough diamond's internal and external characteristics. We determine the optimal cut to maximize the stone's value, considering factors like crystal orientation, inclusions, and potential yield. This technical assessment ensures we achieve the best possible size and quality from each rough diamond.",
      ],
    },
    {
      id: "marking",
      title: "Marking",
      video: Marking,
      descriptions: [
        "Our skilled markers use precision 3D laser technology to map out the cutting points on each rough diamond. We carefully mark the stone according to its natural crystal structure and internal characteristics. This crucial step determines how we'll divide the rough to achieve optimal clarity, weight retention, and final value.",
      ],
    },
    {
      id: "sawing",
      title: "Sawing",
      video: Sawing,
      descriptions: [
        "We use state-of-the-art laser cutting technology to precisely divide rough diamonds along the marked lines. For specific stones, we employ traditional blade sawing techniques when they better suit the diamond's characteristics. Our sawing process ensures clean, precise separation while minimizing any potential damage to the stone.",
      ],
    },
    {
      id: "bruting",
      title: "Blocking & Bruting",
      video: Bruting,
      descriptions: [
        "During this phase, we shape the diamond using specialized machines that rotate the stone against diamond-coated disks. For round brilliants, our bruting process carefully grinds the stone to achieve perfect roundness. We continuously monitor symmetry and proportions throughout this stage to ensure precise geometric alignment.",
      ],
    },
    {
      id: "polishing",
      title: "Polishing",
      video: Polishing,
      descriptions: [
        "In the final stage, our master polishers cut and polish each facet using precision-controlled machines with diamond-coated wheels. We carefully angle each facet to maximize light return and brilliance. Regular quality checks during polishing ensure we maintain exact angles and achieve optimal symmetry for maximum sparkle and fire.",
      ],
    },
    {
      id: "grading",
      title: "Grading",
      video: Grading,
      descriptions: [
        "Our comprehensive grading process evaluates diamonds beyond the standard 4Cs (Cut, Color, Clarity, and Carat). We employ advanced optical scanning technology to assess light performance, measuring factors like brilliance, fire, and scintillation. Each stone undergoes microscopic examination by expert gemologists who evaluate additional characteristics such as crystal strain patterns and precise symmetry measurements. Following our internal assessment, diamonds are sent to leading international laboratories for independent certification, ensuring each stone meets both industry standards and our elevated quality criteria.",
      ],
    },
  ];

  const videoRefs = useRef([]);

  useEffect(() => {
    updateMetaTags({
      title:
        "What We Do - From Rough Diamonds to Polished Perfection | Arjiv Exports",
      description:
        "Learn about the process at Arjiv Exports, where rough diamonds are sorted, cut, polished, and graded to create the finest polished diamonds. From sorting and planning to polishing and certification, we ensure the highest standards in diamond craftsmanship.",
      keywords:
        "diamond manufacturing process, diamond sorting, diamond cutting, diamond polishing, diamond grading, rough diamonds, polished diamonds, diamond craftsmanship, diamond certification, diamond planning, diamond sawing, diamond bruting, Arjiv Exports diamond process",
      canonical: "https://www.arjivexports.com/whatwedo",
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target;

          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    videoRefs.current.forEach((video) => {
      observer.observe(video);
    });

    return () => {
      videoRefs.current.forEach((video) => {
        observer.unobserve(video);
      });
    };
  }, []);

  return (
    <section className="main-content pt-0 what-we-do">
      <GridBanner />
      <Container>
        <div className="process-content">
          <h2 className="mb-5 excellence text-white text-capitalize">
            Our Diamond Manufacturing Process
          </h2>
          {processes.map((x, index) => (
            <Row
              id={x.id}
              key={x.id}
              className={`process-box mb-5 align-items-center ${
                index % 2 === 0 ? "" : "flex-row-reverse"
              }`}
            >
              <Col
                md="6"
                className={`text-center px-4 ${
                  index % 2 === 0 ? "flip-mirror" : ""
                }`}
              >
                {x.img ? (
                  <img src={x.img} alt={x.title} />
                ) : (
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    src={x.video}
                    loop
                    muted
                    className="w-100"
                    loading="lazy"
                    preload="none"
                  />
                )}
              </Col>

              <Col md="6">
                <h2 className="process-title">{x.title}</h2>
                {x.descriptions.map((desc, idx) => (
                  <p
                    className="process-description text-justify mb-3"
                    key={idx}
                  >
                    {desc}
                  </p>
                ))}
              </Col>
            </Row>
          ))}
          <div className="journeryTitle h-100">
            <p>
              "Each diamond undergoes strict quality control between stages,
              ensuring it meets our exacting standards before proceeding to the
              next step. Our entire process is designed to maximize the beauty
              and value of every stone while maintaining the highest quality
              standards in the industry."
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default WhatWeDo;
