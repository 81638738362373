import React from "react";
import { Col, Container, Row } from "reactstrap";
// import rjc from "Assets/Image/Origins/rjc.png";
import rjc from "Assets/Image/Origins/logo-rjc-white.png";
// import canadamark from "Assets/Image/Origins/canadamark.png";
// import canadamark from "Assets/Image/Origins/CANADAMARK10.png";
import canadamark from "Assets/Image/Origins/canadamark1.png";
import gia from "Assets/Image/Origins/gia.png";
import tracr from "Assets/Image/Origins/tracr.png";
import itrace from "Assets/Image/Origins/itrace.png";
import { isTab } from "Utility/Util";

const OriginNAlliance = () => {
  // const origins = [canadamark, gia, rjc, tracr,  itrace];
  const origins = [
    { src: canadamark, key: 'canadamark' },
    { src: gia, key: 'gia' },
    { src: rjc, key: 'rjc' },
    { src: tracr, key: 'tracr' },
    { src: itrace, key: 'itrace' },
  ];

  return (
    <section className="originNAlliances py-lg-5 py-md-4 py-3 px-lg-5 px-3">
      <Container fluid className="text-center px-lg-5 px-2">
        <h2 className="mb-5 excellence text-white text-capitalize">
          Alliance | Origin{" "}
        </h2>
        <Row className="origin-details d-flex align-items-center flex-md-nowrap flex-wrap justify-content-between pt-md-5 pt-2 my-md-5 my-md-4 my-3 g-5 g-2 g-1 g-3">
          {origins.map((x, index) => (
            <Col key={index} className="origin-details-box">
              <img className={`${x.key === 'rjc' && !isTab() ? 'primary-holds' : ''}`} src={x.src} alt={`${x.key} Origin`} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OriginNAlliance;
