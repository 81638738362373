import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";

import { demand } from "Utility/api";
import { callApi } from "Utility/axios";
import { ToastContainer, toast } from "react-toastify";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Button, Col, Container, FormText, Row } from "reactstrap";

import Loader from "Components/Loader";

import "./bodysection.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

const phoneInput = React.forwardRef((props, ref) => {
  return (
    <input
      {...props}
      ref={ref} // Use ref here to maintain focus functionality
      className="form-control" // Your custom class
    />
  );
});

const OrderForm2 = (props) => {
  const [file, setFile] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState({
    shape: "",
    email: "",
    fromCarat: "",
    toCarat: "",
    fromColor: "",
    toColor: "",
    fromClarity: "",
    toClarity: "",
    fromLength: "",
    toLength: "",
    fromWidth: "",
    toWidth: "",
    fromHeight: "",
    toHeight: "",
    mobileNumber: "",
    fromFluo: "",
    toFluo: "",
    message: "",
    fromRatio: "",
    toRatio: "",
    fromRange: "",
    toRange: "",
    timeLimit: "",
  });

  const GetSelectedValue = (e) => {
    const control = e.target;
    const name = control.name;
    const tvalue = control.value;
    setValue({ ...value, [name]: tvalue });
  };

  const FileChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (file) {
      if (file.size > maxSize) {
        e.target.value = null;
        return toast.error("Maximum 10 MB is Acceptable.");
      } else {
        setFile(file);
      }
    }
  };

  const SetShapeList = (e) => {
    const imageName = e.target.name;
    let list = [...value.ShapeList];
    if (e.target.checked) list = [...list, imageName];
    else list = list.filter((img) => img !== imageName);
    setValue({ ...value, ShapeList: list });
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (!value.shape || !value.fromCarat || !value.toCarat || !value.fromColor || !value.toColor || !value.fromClarity || !value.toClarity || !phoneNumber || !value.email) {
      toast.dark('Field is required');
      return;
    }
  
    const formData = new FormData();
  
    formData.append("fromCarat", value.fromCarat);
    formData.append("toCarat", value.toCarat);
    formData.append("fromColor", value.fromColor);
    formData.append("toColor", value.toColor);
    formData.append("fromClarity", value.fromClarity);
    formData.append("toClarity", value.toClarity);
    formData.append("fromLength", value.fromLength);
    formData.append("toLength", value.toLength);
    formData.append("fromWidth", value.fromWidth);
    formData.append("toWidth", value.toWidth);
    formData.append("fromHeight", value.fromHeight);
    formData.append("toHeight", value.toHeight);
    formData.append("fromFluo", value.fromFluo);
    formData.append("toFluo", value.toFluo);
    formData.append("message", value.message);
    formData.append("fromRatio", value.fromRatio);
    formData.append("toRatio", value.toRatio);
    formData.append("fromRange", value.fromRange);
    formData.append("toRange", value.toRange);
    formData.append("timeLimit", value.timeLimit);
    formData.append("mobileNumber", phoneNumber);
    formData.append("shape", value.shape);
    formData.append("email", value.email);
    if (file) formData.append("file", file);
  
    const [err, data] = await callApi({ ...demand, payload: formData });
    
    if (!err && data && data.code === "OK") {
      toast.success(data.message);
      setLoader(false);
      setTimeout(() => {
        window.location.href = '/customization';
      }, 2000);
    } else {
      toast.error("Failed to send the Demand. Please try again.");
      setLoader(false);
    }
  };  

  return (
    <>
      {loader && <Loader />}
      <ToastContainer />
      <Form enctype="multipart/form-data" onSubmit={Submit}>
        <Container className="px-4 py-3">
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="color">Select Shape * :</Label>
                <Row>
                  <Col md={12}>
                    <Input
                      type="select"
                      name="shape"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {shapes.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <Col className="p-0">
                <FormGroup className="col">
                  <Label py={0} for="file">
                    Upload Doc :
                  </Label>
                  <Col md={12} className="p-0">
                    <Input
                      type="file"
                      name="file"
                      id="fileselection"
                      onChange={FileChange}
                      className="h-100 form-control pointer"
                      aria-label="Select file of shape"
                      accept=".png, .jpg, .jpeg, .pdf, image/*, application/pdf"
                    />
                    <FormText size={100}>Only Image / PDF is Acceptable *</FormText>
                  </Col>
                </FormGroup>
              </Col>
            </Col>
          </Row>

          {/* <Row xs={1} sm={1} md={1} className="formgroup">
            <Col className="Shapeholder">
              <FormGroup className="shapegroup">
                <Label className="selectshape">Select Shape :</Label>
                <Row>
                  <ul>
                    {images.map((img, index) => {
                      const id = `cb${index + 1}`;
                      // console.log("Image ::: ", images[index]);
                      console.log(value.ShapeList);
                      return (
                        <li key={index}>
                          <input
                            type="checkbox"
                            id={id}
                            name={images[index]}
                            className="shapeitem"
                            onChange={SetShapeList}
                            value={images[index]}
                          />
                          <label for={id}>
                            <img src={img[1]} alt="" />
                          </label>
                        </li>
                      );
                    })}
                    <li>
                      <label for="cb24">
                        <span>Other</span>
                      </label>
                      <input
                        type="checkbox"
                        id="cb24"
                        className="shapeitem-other"
                      />
                    </li>
                  </ul>
                </Row>
              </FormGroup>
            </Col>
          </Row> */}
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="carat">Carat * :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="fromCarat"
                      id="carat"
                      placeholder="From Carat"
                      required
                      onChange={GetSelectedValue}
                      // min={0.1}
                      // max={100}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="toCarat"
                      id="carat"
                      placeholder="To Carat"
                      required
                      onChange={GetSelectedValue}
                      // min={0.1}
                      // max={100}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="color">Color * :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="fromColor"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {colorsform.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="toColor"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {colorsto.map((color, index) => (
                        <option value={color[0]} key={index}>
                          {color[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="clarity">Clarity * :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="fromClarity"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {claritiesfrom.map((clarity, index) => (
                        <option value={clarity[0]} key={index}>
                          {clarity[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="toClarity"
                      id="Select"
                      onChange={GetSelectedValue}
                      required
                    >
                      {claritiesto.map((clarity, index) => (
                        <option value={clarity[0]} key={index}>
                          {clarity[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="length">Length :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="fromLength"
                      id="length"
                      placeholder="From Length"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="toLength"
                      id="length"
                      placeholder="To Length"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="width">Width :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="fromWidth"
                      id="width"
                      placeholder="From Width"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="toWidth"
                      id="width"
                      placeholder="To Width"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="height">Height :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="fromHeight"
                      id="height"
                      placeholder="From Height"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="toHeight"
                      id="height"
                      placeholder="To Height"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="Ratio">Ratio :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="fromRatio"
                      id="ratio"
                      placeholder="From Ratio"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="toRatio"
                      id="ratio"
                      placeholder="To Ratio"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="Fluorescence">Fluorescence :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="fromFluo"
                      id="fluorescence"
                      placeholder="Select From Fluorescence"
                      onChange={GetSelectedValue}
                    >
                      {flsfrom.map((fl, index) => (
                        <option value={fl[0]} key={index}>
                          {fl[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="toFluo"
                      id="fluorescence"
                      placeholder="Select To Fluorescence"
                      onChange={GetSelectedValue}
                    >
                      {flsto.map((fl, index) => (
                        <option value={fl[0]} key={index}>
                          {fl[1]}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col>
              <FormGroup>
                <Label for="range">Budget Range :</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="fromRange"
                      id="range"
                      placeholder="From Range"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="number"
                      name="toRange"
                      id="range"
                      placeholder="To Range"
                      onChange={GetSelectedValue}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="timelimit">Time Limit :</Label>
                      <Input
                        type="number"
                        name="timeLimit"
                        id="timelimit"
                        placeholder="In How Many Days You Need"
                        onChange={GetSelectedValue}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row xs={1} sm={1} md={2} className="formgroup">
            <Col lg={4} md={6} sm={12}>
              <FormGroup>
                <Label for="contact">Mobile No * :</Label>
                <Row>
                  <Col md={12} center>
                    <PhoneInput
                      defaultCountry="US"
                      country="US"
                      placeholder="Enter Mobile Number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      name="mobileNumber"
                      required
                      inputComponent={phoneInput}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <FormGroup>
                <Label for="email">Email ID * :</Label>
                <Row>
                  <Col md={12}>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      onChange={GetSelectedValue}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <FormGroup>
                <Label for="Message">Message :</Label>
                <Input
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Write Your Message"
                  onChange={GetSelectedValue}
                />
                <FormText size={100}>We Provide Only Loose Diamonds *</FormText>
              </FormGroup>
            </Col>
          </Row>

        </Container>
        <div className="modelfooter">
          <Row>
            <Col>
              <Button type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default OrderForm2;

const colorsform = [
  ["", "Select From Color"],
  ["D", "D"],
  ["E", "E"],
  ["F", "F"],
  ["G", "G"],
  ["H", "H"],
  ["I", "I"],
  ["J", "J"],
  ["K", "K"],
  ["L", "L"],
  ["M", "M"],
  ["N", "N"],
  ["O-P", "O-P"],
  ["Q-R", "Q-R"],
  ["S-T", "S-T"],
  ["U-V", "U-V"],
  ["W-X", "W-X"],
  ["Y-Z", "Y-Z"],
  ["FANCY", "FANCY"],
  ["L-FANCY", "L-FANCY"],
  ["I-FANCY", "I-FANCY"],
  ["D-FANCY", "D-FANCY"],
  ["V-FANCY", "V-FANCY"],
  ["LB", "LB"],
];

const colorsto = [
  ["", "Select To Color"],
  ["D", "D"],
  ["E", "E"],
  ["F", "F"],
  ["G", "G"],
  ["H", "H"],
  ["I", "I"],
  ["J", "J"],
  ["K", "K"],
  ["L", "L"],
  ["M", "M"],
  ["N", "N"],
  ["O-P", "O-P"],
  ["Q-R", "Q-R"],
  ["S-T", "S-T"],
  ["U-V", "U-V"],
  ["W-X", "W-X"],
  ["Y-Z", "Y-Z"],
  ["FANCY", "FANCY"],
  ["L-FANCY", "L-FANCY"],
  ["I-FANCY", "I-FANCY"],
  ["D-FANCY", "D-FANCY"],
  ["V-FANCY", "V-FANCY"],
  ["LB", "LB"],
];

const claritiesfrom = [
  ["", "Select From Clarity"],
  ["FL", "FL"],
  ["IF", "IF"],
  ["VVS1", "VVS1"],
  ["VVS2", "VVS2"],
  ["VS1", "VS1"],
  ["VS2", "VS2"],
  ["SI1", "SI1"],
  ["SI2", "SI2"],
  ["SI3", "SI3"],
  ["I1", "I1"],
  ["I2", "I2"],
  ["I3", "I3"],
  ["I4", "I4"],
];

const claritiesto = [
  ["", "Select To Clarity"],
  ["FL", "FL"],
  ["IF", "IF"],
  ["VVS1", "VVS1"],
  ["VVS2", "VVS2"],
  ["VS1", "VS1"],
  ["VS2", "VS2"],
  ["SI1", "SI1"],
  ["SI2", "SI2"],
  ["SI3", "SI3"],
  ["I1", "I1"],
  ["I2", "I2"],
  ["I3", "I3"],
  ["I4", "I4"],
];

const flsfrom = [
  ["", "Select From Fluorescence"],
  ["NONE", "NONE"],
  ["FAINT", "FAINT"],
  ["MEDIUM", "MEDIUM"],
  ["STRONG", "STRONG"],
  ["VERY STG", "VERY STG"],
];
const flsto = [
  ["", "Select To Fluorescence"],
  ["NONE", "NONE"],
  ["FAINT", "FAINT"],
  ["MEDIUM", "MEDIUM"],
  ["STRONG", "STRONG"],
  ["VERY STG", "VERY STG"],
];

const shapes = [
  ["", "Select Shape"],
  ["round", "Round"],
  ["pear", "Pear"],
  ["oval", "Oval"],
  ["heart", "Heart"],
  ["marquise", "Marquise"],
  ["princess", "Princess"],
  ["emerald", "Emerald"],
  ["radiant", "Radiant"],
  ["triangle", "Triangle"],
  ["kite", "Kite"],
  ["hexagon", "Hexagon"],
  ["cushion", "Cushion"],
  ["baguette", "Baguette"],
  ["bullet", "Bullet"],
  ["oldMiner", "Old Miner"],
  ["oldEuropean", "Old European"],
  ["other", "Other"],
];