import React from "react";
import { Container } from "reactstrap";
import "../Theme.css";

import single1 from "Assets/Image/Wireframe/single1.png";
import single2 from "Assets/Image/Wireframe/single2.png";

import rose1 from "Assets/Image/Wireframe/rose1.png";
import rose2 from "Assets/Image/Wireframe/rose2.png";
import rose3 from "Assets/Image/Wireframe/rose3.png";

import oldmine1 from "Assets/Image/Wireframe/oldmine1.png";
import oldmine2 from "Assets/Image/Wireframe/oldmine2.png";

import brilliant1 from "Assets/Image/Wireframe/brilliant1.png";
import brilliant2 from "Assets/Image/Wireframe/brilliant2.png";

import step1 from "Assets/Image/Wireframe/step1.png";
import step2 from "Assets/Image/Wireframe/step2.png";
import step3 from "Assets/Image/Wireframe/step3.png";

import fancy1 from "Assets/Image/Wireframe/fancy1.png";
import fancy2 from "Assets/Image/Wireframe/fancy2.png";

import custom1 from "Assets/Image/Wireframe/custom1.png";
import custom2 from "Assets/Image/Wireframe/custom2.png";
import custom3 from "Assets/Image/Wireframe/custom3.png";

import estep1 from "Assets/Image/Wireframe/estep1.png";
import estep2 from "Assets/Image/Wireframe/estep2.png";
import estep3 from "Assets/Image/Wireframe/estep3.png";

const Milestone = () => {
  return (
    <main className="main-content">
      <Container>
        <div className="row m0 journeryTitle mb-5">
          <h3 className="my-lg-4 my-3">
            <span>THE</span>
            <br />
            <span>JOURNEY</span>
          </h3>
          <h6 className="my-lg-4 my-3">WELCOME TO THE WORLD OF ARJIV.</h6>
          <br />
          <br />
          <p>
            Founded in 1985, ARJIV EXPORTS has always believed in Innovation,
            Excellence and Proficiency. Since then,we have been striving to meet
            the highest standards of manufacturing each diamond.
          </p>
        </div>
        <div className="milestone-container">
          <div className="milestone-wrapper">
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={single2} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title mb-2 pb-2">1985 - Single Cut Diamonds</h3>
                <p className="description">
                  A single cut on the other hand is a simple form of cutting a
                  round diamond with only 17 or 18 facets. If you compare a{" "}
                  <strong className="text-capitalize">
                    Single Cut Diamond
                  </strong>{" "}
                  (usually small Carat Weights of 1.2mm or less) , to a Full Cut
                  Diamond (58 facets), you’d quickly see the difference. Small
                  high quality single cut diamonds are highly prized especially
                  in the watch industry, and because there are fewer but larger
                  facets, they give off a majestic dance of light and colour,
                  that is often lost in a sea of white light with the full cut
                  stones
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={single2} alt="" srcset="" />
                  <img src={single1} alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={rose1} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title mb-2 pb-2">2000 - Rose Cut Diamonds</h3>
                <p className="description">
                  The <strong className="text-capitalize"> rose cut </strong> is
                  one of the oldest cutting style for diamonds mordern jewellery
                  designers are creating edges(Contemparory jewellery with an
                  interesting, old world vibe by adding rose cut.) The ancient
                  art of faceting a stone to look like an opening nose,
                  originated in India in the 16th century. The result is a stone
                  that is still coveted today by high-end designers and
                  consumers alike. This brilliant time-honored cut allows light
                  to reflect within 24 domed facets(Size 0.01 to 20.00 cts),
                  Colour(o-z.natural fancy colour).
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={rose1} width={50} height={50} alt="" srcset="" />
                  <img src={rose2} width={50} height={50} alt="" srcset="" />
                  <img src={rose3} width={50} height={50} alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={oldmine2} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title">2002 - Old Mine Cut Diamonds</h3>
                <p className="description">
                  Brought back a piece of history by reviving the classic{" "}
                  <strong className="text-capitalize"> Old Mine Cut.</strong>{" "}
                  This vintage-style diamond cut harkens back to the 18th
                  century, characterized by its shape, deep facets, and antique
                  charm. By introducing these cuts to our portfolio, we enabled
                  our customers to experience a sense of timeless luxury,
                  connecting them with the craftsmanship of a bygone era.
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={oldmine2} alt="" srcset="" />
                  <img src={oldmine1} alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={brilliant2} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title">2008 - Brilliant Cut Diamonds</h3>
                <p className="description">
                  A brilliant cut is simple a diamond or other gemstone cut in a
                  particular form with numerous facets so as to have exceptional
                  brilliance. The shape resembles of a cone and provides
                  maximised light return through the top of a diamond.{" "}
                  <strong className="text-capitalize"> Round Brilliant </strong>
                  Cuts are moreover used in modern jewellery.
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={brilliant2} />
                  <img src={brilliant1} />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={fancy1} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title">2012 - Fancy Coloured Diamonds</h3>
                <p className="description">
                  Natural, Intense{" "}
                  <strong className="text-capitalize">
                    fancy coloured diamonds
                  </strong>{" "}
                  are as rare as they exquisite. Arjiv exports carries a variety
                  of fancy colours in a range of shapes, sizes, colours and
                  clarity. They are 100% natural and un-treated with heat or
                  chemicals so not to compromise their value or quality.
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={fancy2} alt="" srcset="" />
                  <img src={fancy1} alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={step2} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title">2015 - Step Cut Diamonds</h3>
                <p className="description">
                  Stones whose outlines are either square or rectangular and
                  whose facets are rectilinear and arranged parallel to the
                  griddle known as{" "}
                  <strong className="text-capitalize">
                    Step-or-step cut stones.
                  </strong>{" "}
                  These stones often have their corners truncated creating an
                  embrald cut with octagonal outline.
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img
                    className=""
                    src={step3}
                    width={50}
                    height={50}
                    alt=""
                    srcset=""
                  />
                  <img
                    className=""
                    src={step1}
                    width={50}
                    height={50}
                    alt=""
                    srcset=""
                  />
                  <img
                    className=""
                    src={step2}
                    width={50}
                    height={50}
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={estep1} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title">2017 - Evolution of Step Cuts</h3>
                <p className="description">
                  we embraced innovation by evolving the classic{" "}
                  <strong className="text-capitalize">step-cut diamond.</strong>{" "}
                  With a focus on creating modified cuts, we transformed the
                  traditional step cuts into something more intricate and
                  unique, pushing the boundaries of diamond cutting. This new
                  approach allowed us to offer diamonds with enhanced brilliance
                  and a striking geometric look, catering to modern jewelry
                  trends while maintaining a touch of elegance.
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={estep2} alt="" srcset="" />
                  <img src={estep1} alt="" srcset="" />
                  <img src={estep3} alt="" srcset="" />
                </div>
              </div>
            </div>
            <div className="milestone-item">
              <div className="milestone-year-box">
                <div className="milestone-year">
                  <img src={custom2} alt="" srcset="" />
                </div>
              </div>
              <div className="milestone-content">
                <h3 className="title">
                  2020 - Custom Cuts for Limitless Creativity
                </h3>
                <p className="description">
                  Arjiv Exports has been on the cutting edge of diamond
                  innovation. We now specialize in creating{" "}
                  <strong className="text-capitalize"> custom cuts</strong> that
                  break traditional boundaries, allowing our customers to
                  realize their most imaginative jewelry designs. Whether it’s
                  modifying shapes, angles, or facet arrangements, we work
                  closely with our clients to craft diamonds that fit their
                  exact specifications. From avant-garde cuts to personalized
                  modifications, our bespoke diamond solutions open up new
                  possibilities for jewelry creators worldwide.
                </p>
                <div className="arjiv-evolution d-flex align-items-center justify-content-end w-auto">
                  <img src={custom2} />
                  <img src={custom1} />
                  <img src={custom3} />
                </div>
              </div>
            </div>
          </div>
          <div className="our-future my-lg-5 my-md-4 my-3 text-center">
            <h2>The Future of Arjiv Exports</h2>
            <p>
              As we continue to innovate and evolve, Arjiv Exports is committed
              to pushing the art of diamond cutting even further. Our goal is to
              blend tradition with modernity, constantly creating diamonds that
              not only reflect the highest standards of craftsmanship but also
              inspire and empower designers around the globe.
            </p>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Milestone;
