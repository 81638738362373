import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import hktdc from "Assets/Image/exhibit/hktdc.jpg";
import tombas from "Assets/Image/exhibit/tombas.jpg";
import gemGenve from "Assets/Image/exhibit/gemGenve.jpg";
import jwlArabia from "Assets/Image/exhibit/jwlArabia.jpg";
import vicenzaoro from "Assets/Image/exhibit/vicenzaoro.jpg";
import jwlgemSingapore from "Assets/Image/exhibit/jwlgemSingapore.jpg";

import { updateMetaTags } from "Utility/Util";

import "./bodysection.css";

function Events() {

  useEffect(() => {
    updateMetaTags({
      title: "Events and Exhibitions",
      description: "Arjiv Exports participate in many prestigious diamond and jewelry exhibitions.",
      keywords: "diamond exhibitions, jewelry exhibitions, diamond trade shows, diamond manufacturers, gemstone exhibitions, luxury jewelry events, international jewelry exhibitions, gem and diamond trade shows, diamond industry, global diamond exhibitions",
      canonical: "https://www.arjivexports.com/events",
    });
  }, []);

  return (
    <div className="main-content events pb-5">
      <Container className="mt-5">
      <h3>EVENTS UPDATES</h3>
      <p>WE EXHIBIT IN THE FOLLOWING SHOWS</p>
        <Row xs="1" sm="1" md="2">
          <Col className="py-0 px-1 mb-2">
            <div className="zoom">
              <img src={gemGenve} alt="Hongkong" />
              <p className="bg-secondary p-2 position-relative">GemGeneve</p>
            </div>
          </Col>
          <Col className="py-0 px-1 mb-2">
            <div className="zoom">
              <img src={jwlgemSingapore} alt="Hongkong" />
              <p className="bg-secondary p-2 position-relative">Jewellery & Gem World Singapore</p>
            </div>
          </Col>
          <Col md={6} className="p-0">
            <Row>
              <Col md={6} className="py-0 px-1 mb-2">
              <div className="zoom">
                <img src={jwlArabia} alt="Arabia" />
                <p className="bg-secondary p-1 position-relative">Jewellery Arabia</p>
              </div>
              </Col>
              <Col md={6} className="py-0 px-1 mb-2">
                <div className="zoom">
                  <img src={vicenzaoro} alt="Vincenzaoro" />
                  <p className="bg-secondary p-1 position-relative">Vicenzaoro</p>
                </div>
              </Col>
            </Row>
            </Col>
            <Col md={6} className="p-0">
            <Row>
              <Col md={6} className="py-0 px-1 mb-2">
                <div className="zoom">
                  <img src={hktdc} alt="Singapore" className="bg-white" />
                  <p className="bg-secondary p-1 position-relative">HKTDC</p>
                </div>
              </Col>
              <Col md={6} className="py-0 px-1 mb-2">
                <div className="zoom">
                  <img src={tombas} alt="Miami" />
                  <p className="bg-secondary p-1 position-relative">Miami Beach Antique Show</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Events;
