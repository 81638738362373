import React, { useEffect } from "react";
// import Planning from "Assets/Video/planning2.mp4";
import Planning from "Assets/Video/Planning2.webm";
import Marking from "Assets/Video/Marking.mp4";
import Sawing from "Assets/Video/Sawing.mp4";
// import Bruting from "Assets/Video/Bruting.mp4";
import Bruting from "Assets/Video/Bruting.webm";
// import Polishing from "Assets/Video/Polishing.mp4";
import Polishing from "Assets/Video/Polishing.webm";

const videoData = [
  { src: Planning, label: "Planning", id: "planning" },
  { src: Marking, label: "Marking", id: "marking" },
  { src: Sawing, label: "Sawing", id: "sawing" },
  { src: Bruting, label: "Blocking & Bruting", id: "bruting" },
  { src: Polishing, label: "Polishing", id: "polishing" },
];

const GridBanner = () => {
  const handlePlayVideo = () => {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.play();
    });
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = -250; // Offset of 50px from the top
      const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };
  

  useEffect(() => {
    handlePlayVideo();
  }, []);

  return (
    <main className="grid-banner">
      <section className="main-content">
        <div className="grid-wrapper">
          {videoData.map((video, index) => (
            <div
              key={index}
              className="grid-box"
              onClick={() => scrollToSection(video.id)}
              style={{ cursor: "pointer" }}
            >
              <div className="mask-box">
                <video src={video.src} loop muted className="w-100" />
              </div>
              <h2>{video.label}</h2>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default GridBanner;
