import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import CustomizationImg from "Assets/Image/common/customize-1.jpg";
import CustomizationTabImg from "Assets/Image/common/CustomizationTabImg.jpg";
import { getResponsiveImage } from "Utility/Util";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomOrder from "Components/pages/customorder";
import Modal from "react-awesome-modal";

const Customization = () => {
  const [visible, setVisible] = useState(Boolean(false));
  const currentImg = getResponsiveImage({
    desktop: CustomizationImg,
    tab: CustomizationTabImg,
    mobile: CustomizationTabImg,
  });

  const handleOpenDemand = () => {
    setVisible((prev) => !prev);
  };

  return (
    <>
      <section className="customization">
        <img src={currentImg} className="w-100" />
        <div className="container-fluid h-100">
          <div className="customization-details pl-lg-4 pl-0 py-4 py-md-3 py-2">
            <Row className="h-100 align-items-center justify-content-lg-start justify-content-md-center mx-md-auto">
              <Col xl={5} lg={6} md={8} sm={12}>
                <h4>Diamond Customization </h4>
                <p className="mt-lg-3 mt-md-4 mt-4">
                  With us, bring your unique ideas to life through
                  custom-crafted diamond designs, made exclusively to match your
                  creative vision.
                </p>
                <button
                  className="btn btn-outline-light py-3 px-4 mt-2 mt-lg-4"
                  onClick={() => handleOpenDemand()}
                >
                  Customize Request
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {visible && (
        <Modal
          className="modelcheck overflow-hidden"
          visible={visible}
          width="80%"
          effect="fadeInDown"
          onClickAway={() => handleOpenDemand()}
        >
          <div className="model-content">
            <div className="modelheader p-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0">Send Your Demand</h5>
              <IoIosCloseCircleOutline
                className="pointer"
                onClick={() => handleOpenDemand()}
              />
            </div>

            <div className="modelbody">
              <CustomOrder props={handleOpenDemand} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Customization;
