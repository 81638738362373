import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Row } from "reactstrap";

import Loader from "Components/Loader";
import { callApi } from "Utility/axios";
import { contactUs } from "Utility/api";
import { updateMetaTags } from "Utility/Util";

import "./bodysection.css";
import "react-toastify/dist/ReactToastify.css";

function Contactus() {
  const [loader, setLoader] = useState(false);
  const MsgForm = () => {
    const handleSubmit = async (e) => {
      setLoader(true);
      e.preventDefault();
      const formData = Object.fromEntries(new FormData(e.target));
      const request = {
        ...contactUs,
        payload: formData,
      };

      const [err, data] = await callApi(request);
      if (!err && data && data.code === "OK") {
        toast.success(data?.message);
        setLoader(false);
        document.querySelector('button[type="reset"]').click();
      } else {
        toast.error("Failed to send the message. Please try again.");
        setLoader(false);
      }
    };

    useEffect(() => {
      updateMetaTags({
        title: "Contact Us - Get in Touch with Arjiv Exports",
        description:
          "Contact Arjiv Exports for inquiries about our diamond products and services. We're here to help you with any questions, whether it's about purchasing, customization, or general inquiries.",
        keywords:
          "contact Arjiv Exports, diamond inquiries, customer support, diamond manufacturer contact, reach us, diamond services, diamond products, Arjiv Exports contact form",
        canonical: "https://www.arjivexports.com/contact_us",
      });
    }, []);

    return (
      <Form onSubmit={handleSubmit}>
        {loader && <Loader />}
        <ToastContainer />
        <Row style={{ gap: 10 }}>
          <Col className="px-0">
            <FormGroup className="text-left">
              <Label for="Name">Name *</Label>
              <Input type="text" name="name" placeholder="Full Name" required />
            </FormGroup>
          </Col>
          <Col className="px-0">
            <FormGroup className="text-left">
              <Label for="Email">Email *</Label>
              <Input
                type="email"
                name="email"
                id="Email"
                placeholder="your@email.com"
                required
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="text-left">
          <Label for="subject">Subject *</Label>
          <Input
            type="text"
            name="subject"
            id="subject"
            placeholder="Subject"
            required
          />
        </FormGroup>
        <FormGroup className="text-left">
          <Label for="textarea">Message *</Label>
          <textarea
            className="form-control"
            rows={7}
            type="textarea"
            name="message"
            placeholder="Write Your Message"
            required
            resize={false}
          />
        </FormGroup>

        <Button type="submit" className="mb-lg-5 mb-4 w-100">
          Send Your Message
        </Button>
        <Button className="d-none" type="reset">
          reset
        </Button>
      </Form>
    );
  };

  return (
    <div className="main-content">
      <Container className="py-5 main-content">
        <Row xs="1" sm="1" md="2">
          <Col className="">
            <CardTitle className="title">CONTACT FORM</CardTitle>
            <MsgForm />
            {/* <div>
              <iframe
                width="100%"
                height="400"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Vasta%20Devdi%20Rd,%20Tunki,%20Katargam,%20Surat,%20Gujarat%20395004+(arjiv%20Exports)&amp;t=k&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div> */}
          </Col>
          <Col>
            <CardTitle className="title">CONTACT INFO</CardTitle>
            <CardBody>
              <CardSubtitle className="title2">Head Office</CardSubtitle>
              <CardText className="contacttext">
                Address: CC-7085, Bharat Diamond Bourse, BKC, Bandra East,
                Mumbai, Maharashtra 400051
              </CardText>
              <CardSubtitle className="title2">
                Manufacturing Unit Office
              </CardSubtitle>
              <CardText className="contacttext">
                Address: CS NO 3074, Survey NO 412-1 Block NO. 1C, Opp
                Reshamvala Reyon, Vastadevadi Road Katargam, Surat, Gujarat,
                395004
              </CardText>
              <CardSubtitle className="title2">EMAIL US</CardSubtitle>
              <CardText className="contacttext">
                Email your issues and suggestion for the following
                <br /> Email Address:{" "}
                <a href="mailto:info@arjiv.com">info@arjiv.com</a>
              </CardText>
              <CardSubtitle className="title2">NEED TO CALL US?</CardSubtitle>
              <CardText className="contacttext">
                From Monday to Friday,10:00 AM - 8:00 PM,
                <br /> Call Us at:{" "}
                <a href="tel:+919898300984">+91 98983 00984</a>
              </CardText>
              <CardSubtitle className="title2">
                WE HAVE GREAT SUPPORT
              </CardSubtitle>
              <CardText className="contacttext">
                We provide the best Quality of products to you.We are always
                here to help our lovely customers.We ship our products anywhere
                with more secure.
              </CardText>
            </CardBody>
          </Col>
        </Row>
        <Row xs="1" sm="1" md="2">
          <Col>
            <h2 className="mb-2 excellence text-white text-capitalize">Manufacturing Unit (Surat)</h2>
            <iframe
              className="rounded"
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4450.730021808638!2d72.83393947587598!3d21.214596981368583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ff77461f8c1%3A0x976e1d8c296af3d1!2sArjiv%20Exports!5e1!3m2!1sen!2sin!4v1736333170337!5m2!1sen!2sin"
            ></iframe>
          </Col>
          <Col>
            <h2 className="mb-2 excellence text-white text-capitalize">Head Office (Mumbai)</h2>
            <iframe
              className="rounded"
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4512.384978439379!2d72.8641597!3d19.065523799999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e8a1f99d19%3A0x9058e81c33e92c9e!2sArjiv%20Exports!5e1!3m2!1sen!2sin!4v1736333100104!5m2!1sen!2sin"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contactus;
