import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const PageHeader = (props) => {
  const { title, showPath } = props;
  const location = useLocation();

  // Split the current path into segments
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);

  const renderBreadcrumbs = () => {
    return pathSegments.map((z, index) => {
      const breadcrumbPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
      const isLast = index === pathSegments.length - 1;

      return (
        <BreadcrumbItem key={index} active={isLast}>
          {isLast ? (
            z.replace(/-/g, " ")
          ) : (
            <Link to={breadcrumbPath}>{z.replace(/-/g, " ")} </Link>
          )}
        </BreadcrumbItem>
      );
    });
  };

  return (
    <div className="arjiv-crumb page-header py-4">
      <div className="container">
        <h1 className="text-capitalize mb-5">{title}</h1>
        {/* Reactstrap Breadcrumb Component */}
        {showPath && (
          <Breadcrumb listClassName="justify-content-center">
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>

            {renderBreadcrumbs()}
          </Breadcrumb>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
