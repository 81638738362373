import React from "react";

import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { getSlugify } from "Utility/Util";

import blogsData from "Components/pages/Blogs/blogs.json";
import Breadcrumb from "Components/BreadCrumb";

const BlogDetails = () => {
  const { title } = useParams();
  const blog = blogsData.find((blog) => blog.title === title);

  return (
    <main className="main-content">
      {!blog ? (
        <div className="container mb-5 py-5">
          <h2 className="my-5 py-5">Blog not found!</h2>
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb title={getSlugify(blog.title)} showPath />
          <section className="bg-arjiv py-4">
            <Container>
              <div className="arjiv-article mb-3 pb-4 px-4 mt-3">
                <div className="article-content mt-3">
                  {blog.content.map((section, index) => {
                    if (section.type === "title") {
                      return (
                        <h2 key={index} className="blog-section-title">
                          {section.value}
                        </h2>
                      );
                    } else if (section.type === "description") {
                      return (
                        <p key={index} className="blog-section-description">
                          {section.value}
                        </p>
                      );
                    } else if (section.type === "image") {
                      return (
                        <img
                          key={index}
                          className="img-fluid blog-section-image"
                          src={section.value}
                          alt="Blog"
                        />
                      );
                    } else if (section.type === "list") {
                      return (
                        <ul key={index} className="blog-section-list">
                          {section.value.map((item, i) => (
                            <li className="d-block pb-1" key={i}>
                              &bull; {item}
                            </li>
                          ))}
                        </ul>
                      );
                    }
                    return null;
                  })}
                </div>
                <a href="/blogs" className="btn btn-secondary mr-2">
                  Back to blogs
                </a>
              </div>
            </Container>
          </section>
        </React.Fragment>
      )}
    </main>
  );
};

export default BlogDetails;
