import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import blogs from "Components/pages/Blogs/blogs.json";
import { getSlugify } from "Utility/Util";

const BlogList = () => {
 
  return (
    <div className="main-content pb-5">
      <section className="arjiv-blogs">
        <Container className="mt-5">
          <h2 className="mb-5 excellence text-white text-capitalize">Blogs</h2>
          <Row xs="1" sm="1" md="2" lg={3} className="gap-2 h-100">
            {blogs.map((x) => (
              <Col key={x.blogId} className="blog-box overflow-hidden h-auto d-flex">
                <Link to={`/blogs/${x.title}`} className="h-100 d-flex flex-column">
                  <img src={x.thumbImg} alt={x.title} />
                  <div className="blog-box-details">
                    <h5 className="mb-2 text-capitalize">{getSlugify(x.title)}</h5>
                    <p>{x.description}</p>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default BlogList;
