import React from "react";
import { Col, Container, Row } from "reactstrap";
import rjc from "Assets/Image/common/RJC.jpg";

const RJCBanner = () => {
  return (
    <section className="rjc-banner text-white text-left py-lg-5 py-4">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xl={10} lg={10} md={11} className="p-0">
            <Row className="align-items-center">
              <Col lg={6}>
                {/* <h2 className="mb-4 text-capitalize d-flex align-items-center gap-2"><img src={certiIcon} width={40} alt="Certificate" /> certified Diamond Manufacturer</h2> */}
                <h3 className="mb-4 text-capitalize">
                  Arjiv Exports is a <strong className="highlight">certified Diamond Manufacturer</strong> by the
                  Responsible Jewellery Council. We proudly offer ethically
                  sourced diamonds, ensuring integrity and sustainability from
                  origin to brilliance.
                </h3>
              </Col>
              <Col lg={6} className="text-lg-right text-center">
                <img
                  src={rjc}
                  className="w-100"
                  alt="Responsible Jewellery Council"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RJCBanner;
