import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import fl from "Assets/Image/Clarity/FL.png";
import i from "Assets/Image/Clarity/I.png";
import si from "Assets/Image/Clarity/SI.png";
import vs from "Assets/Image/Clarity/VS.png";
import vvs from "Assets/Image/Clarity/VVS.png";
import d2f from "Assets/Image/Color/D-F.png";
import g2h from "Assets/Image/Color/G-H.png";
import i2j from "Assets/Image/Color/I-J.png";
import k2m from "Assets/Image/Color/K-M.png";
import n2r from "Assets/Image/Color/N-R.png";
import s2z from "Assets/Image/Color/S-Z.png";
import fancy from "Assets/Image/Color/Fancy.png";
import Cut from "Assets/Image/Cut/Cut.png";
import faint from "Assets/Image/Floro/faint.png";
import medium from "Assets/Image/Floro/medium.png";
import none from "Assets/Image/Floro/none.png";
import strong from "Assets/Image/Floro/strong.png";
import veryStrong from "Assets/Image/Floro/very-strong.png";

// Inclusion Images
import Bearding from "Assets/Image/Inclusion/Bearding.png";
import Cavity from "Assets/Image/Inclusion/Cavity.png";
import Bruise from "Assets/Image/Inclusion/Bruise.png";
import Cloude from "Assets/Image/Inclusion/Cloude.png";
import Crystal from "Assets/Image/Inclusion/Crystal.png";
import Feather from "Assets/Image/Inclusion/Feather.png";
import InternalGraining from "Assets/Image/Inclusion/InternalGraining.png";
import Knots from "Assets/Image/Inclusion/Knots.png";
import LaserDrillHole from "Assets/Image/Inclusion/LaserDrillHole.png";
import Needle from "Assets/Image/Inclusion/Needle.png";
import Pinpoint from "Assets/Image/Inclusion/Pinpoint.png";
import TwinningWisp from "Assets/Image/Inclusion/TwinningWisp.png";

// Inclusion ORG Images
import Bearding_Org from "Assets/Image/Inclusion/Org/bearded.png";
import Cavity_Feather_Org from "Assets/Image/Inclusion/Org/cavity.png";
import Bruise_Org from "Assets/Image/Inclusion/Org/bruises.png";
import Cloud_Org from "Assets/Image/Inclusion/Org/clouds.png";
import Crystal_Org from "Assets/Image/Inclusion/Org/crystals.png";
import Feather_Org from "Assets/Image/Inclusion/Org/feathers.png";
import Internal_Graining_Org from "Assets/Image/Inclusion/Org/grainingInternal.png";
import Knot_Org from "Assets/Image/Inclusion/Org/knots.png";
import Laser_Drill_Hole_Org from "Assets/Image/Inclusion/Org/InternalLaserDrilling.png";
import Needle_Org from "Assets/Image/Inclusion/Org/needles.png";
import Pinpoint_Org from "Assets/Image/Inclusion/Org/pinpoints.png";
import Twinning_Wisp_Org from "Assets/Image/Inclusion/Org/twinningWisps.png";

import { updateMetaTags } from "Utility/Util";

const GradingSystem = () => {
  const inclusions = [
    {
      mainImg: Bearding,
      OrgImg: Bearding_Org,
      name: "Bearding",
    },
    {
      mainImg: Cavity,
      OrgImg: Cavity_Feather_Org,
      name: "Cavity",
    },
    {
      mainImg: Bruise,
      OrgImg: Bruise_Org,
      name: "Bruise",
    },
    {
      mainImg: Cloude,
      OrgImg: Cloud_Org,
      name: "Cloude",
    },
    {
      mainImg: Crystal,
      OrgImg: Crystal_Org,
      name: "Crystal",
    },
    {
      mainImg: Feather,
      OrgImg: Feather_Org,
      name: "Feather",
    },
    {
      mainImg: InternalGraining,
      OrgImg: Internal_Graining_Org,
      name: "Internal Graining",
    },
    {
      mainImg: Knots,
      OrgImg: Knot_Org,
      name: "Knots",
    },
    {
      mainImg: LaserDrillHole,
      OrgImg: Laser_Drill_Hole_Org,
      name: "Laser Drill Hole",
    },
    {
      mainImg: Needle,
      OrgImg: Needle_Org,
      name: "Needle",
    },
    {
      mainImg: Pinpoint,
      OrgImg: Pinpoint_Org,
      name: "Pinpoint",
    },
    {
      mainImg: TwinningWisp,
      OrgImg: Twinning_Wisp_Org,
      name: "Twinning Wisps",
    },
  ];

  const whiteInclusionDetail = [ 
    { 
      type: "Clouds",
      detail: "– A cluster of tiny white pinpoint inclusions that create a hazy effect."
    },
    { 
      type: "Feathers",
      detail: "– Internal fractures that appear as white or translucent lines."
    },
    { 
      type: "Needles",
      detail: "– Long, thin white inclusions that resemble tiny rods."
    },
    { 
      type: "Crystals",
      detail: "– White mineral deposits inside the diamond."
    },
    { 
      type: "Graining",
      detail: "– Internal growth patterns that can create a milky appearance."
    },
  ]
  
  const blackInclusionDetail = [ 
    { 
      type: "Black Crystals",
      detail: "– Dark mineral deposits, often of minerals like pyrite or graphite, that can be embedded within the diamond."
    },
    { 
      type: "Pinpoints",
      detail: "– Very small black inclusions that may appear as tiny dots scattered within the diamond."
    },
    { 
      type: "Black Twinning Wisps",
      detail: "– Dark thread-like inclusions formed by the intergrowth of crystal structures in a diamond."
    },
    { 
      type: "Dark Clouds",
      detail: "– Clusters of dark inclusions that can create a shadowy or smoky effect within the diamond."
    },
    { 
      type: "Needles",
      detail: "– Thin, black, hair-like inclusions that can look similar to black threads."
    },
    { 
      type: "Black Feathers",
      detail: "– Fractures or cleavages that appear as dark lines within the stone, often indicating internal stress."
    },
  ]

  useEffect(() => {
    updateMetaTags({
      title: "Arjiv Diamond Grading System",
      description:
        "Discover everything about diamond grading with our detailed guide to the 4Cs: Cut, Clarity, Color, and Carat. Find the perfect diamond with confidence.",
      keywords:
        "diamond grading, diamond 4Cs, diamond cut, diamond clarity, diamond color, diamond carat, diamond quality, diamond education",
      canonical: "https://www.arjivexports.com/grading",
    });
  }, []);

  return (
    <main className="main-content grading pt-0">
      <section className="grading-area">
        <Container>
          <div className="">
            <h1 className="text-uppercase my-5">ARJIV GRADING SYSTEM</h1>
          </div>
        </Container>
      </section>
      <section className="grading">
        <div className="grading-detail-area py-lg-5 py-md-4 py-3">
          <div className="grading-details text-center mx-2 my-2 pb-3 pt-0 mx-lg-5 mx-md-4">
            <p>
              The <strong>Arjiv Grading System</strong> follows the global
              standard of the 4Cs: carat weight, cut, color, and clarity, while
              also incorporang addional valuable parameters that go beyond these
              standards. Expert gemologists use precise tools and methodologies
              to assess each characterisc, providing a comprehensive evaluation of
              the diamond's overall quality and value.
            </p>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2>Shape of Diamond</h2>
            <p>
              When purchasing diamonds, shape plays a crucial role. Each diamond
              shape craed by Arjiv Export is disnct, offering its own unique
              characteriscs and appeal.
            </p>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Carat</h2>
            <div className="overflow-auto w-100">
              <table border="1" className="w-100 text-center">
                <tr className="w-100">
                  <td width={"14.28%"}>0.30 - 0.39</td>
                  <td width={"14.28%"}>0.40 - 0.49</td>
                  <td width={"14.28%"}>0.50 - 0.69</td>
                  <td width={"14.28%"}>0.70 - 0.89</td>
                  <td width={"14.28%"}>0.90 - 0.99</td>
                  <td width={"14.28%"}>1.00 - 1.49</td>
                  <td width={"14.28%"}>1.50 - 1.99</td>
                </tr>
                <tr>
                  <td>2.00 - 2.99</td>
                  <td>3.00 - 3.99</td>
                  <td>4.00 - 4.99</td>
                  <td>5.00 - 5.99</td>
                  <td>6.00 - 6.99</td>
                  <td>10.00 +</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Clarity</h2>
            <p>
              Clarity refers to the presence of inclusions and blemishes in a
              diamond, with flawless diamonds being exceponally rare and
              valuable. Inclusions, often traces of non-crystallized carbon, can
              affect a diamond’s brilliance by disrupng light dispersion.
            </p>
            <Row className="base-grid d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-nowrap overflow-x-auto">
              <Col className="w-100 text-center">
                <img
                  src={fl}
                  alt=""
                  width={100}
                  height={100}
                  className="mb-4"
                />
                <div>
                  <p className="mb-1">FL - Flaw Less</p>
                  <p>IF - Internally Flaw Less</p>
                </div>
              </Col>
              <Col className="w-100 text-center">
                <img
                  src={vvs}
                  alt=""
                  width={100}
                  height={100}
                  className="mb-4"
                />
                <div>
                  <p className="mb-1">VVS1 / VVS2</p>
                  <p>Very Very Slightly Included</p>
                </div>
              </Col>
              <Col className="w-100 text-center">
                <img
                  src={vs}
                  alt=""
                  width={100}
                  height={100}
                  className="mb-4"
                />
                <div>
                  <p className="mb-1">VS1 / VS2</p>
                  <p>Very Slightly Included</p>
                </div>
              </Col>
              <Col className="w-100 text-center">
                <img
                  src={si}
                  alt=""
                  width={100}
                  height={100}
                  className="mb-4"
                />
                <div>
                  <p className="mb-1">SI1 / SI2 / SI3</p>
                  <p>Slightly Included</p>
                </div>
              </Col>
              <Col className="w-100 text-center">
                <img src={i} alt="" width={100} height={100} className="mb-4" />
                <div>
                  <p className="mb-1">I1 / I2 / I3</p>
                  <p>Included</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Color</h2>
            <p>
              Diamond color ranges from colorless to shades of yellow and brown,
              graded on a scale from D to Z. Fancy color diamonds, on the other
              hand, exhibit vibrant hues such as blue, pink, and yellow, valued
              for their rarity and striking beauty.
            </p>
            <Row className="base-grid d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-nowrap overflow-x-auto">
              <div className="w-100 text-center">
                <img src={d2f} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">D | E | F</p>
                  <p>Colorless</p>
                </div>
              </div>
              <div className="w-100 text-center">
                <img src={g2h} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">G | H</p>
                  <p>Near Colorless</p>
                </div>
              </div>
              <div className="w-100 text-center">
                <img src={i2j} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">I | J</p>
                  <p>Slightly Tinted</p>
                </div>
              </div>
              <div className="w-100 text-center">
                <img src={k2m} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">K | L | M</p>
                  <p>Faint</p>
                </div>
              </div>
              <div className="w-100 text-center">
                <img src={n2r} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">N | O-P | Q-R</p>
                  <p>Very Light</p>
                </div>
              </div>
              <div className="w-100 text-center">
                <img src={s2z} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">S-T | U-V | W-X | Y-Z</p>
                  <p>Light</p>
                </div>
              </div>
              <div className="w-100 text-center">
                <img src={fancy} alt="" width={100} className="mb-2" />
                <div>
                  <p className="mb-1">FANCY</p>
                  <p>Yellow</p>
                </div>
              </div>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Cut</h2>
            <p>
              The cut of a diamond plays a key role in its sparkle and
              brilliance, directly influenced by the skill of the diamond cutter.
              Even with excellent color and clarity, a poorly executed cut can
              diminish the stone's ability to reflect light, reducing its
              overall radiance. A precise cut ensures maximum brilliance and
              enhances the diamond's visual impact.
            </p>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-100 text-center">
                  <tr className="w-100">
                    <td width={"35%"}>EX</td>
                    <td width={"20%"}>VG</td>
                    <td width={"20%"}>GD</td>
                    <td width={"20%"}>F</td>
                  </tr>
                  <tr className="font-normal">
                    <td>Excellent Cut</td>
                    <td>Very Good Cut</td>
                    <td>Good Cut</td>
                    <td>Fair Cut</td>
                  </tr>
                </table>
              </div>
              <img src={Cut} className="my-3" alt="" />
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Polish</h2>
            <p>
              Polish refers to the degree of smoothness on a diamond's facets. A
              well-polished diamond enhances its ability to reflect light,
              contribung to its overall brilliance and visual appeal.
            </p>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-100 text-center">
                  <tr className="w-100">
                    <td width={"25%"}>EX</td>
                    <td width={"25%"}>VG</td>
                    <td width={"25%"}>GD</td>
                    <td width={"25%"}>F</td>
                  </tr>
                  <tr className="font-normal">
                    <td>Excellent Polish</td>
                    <td>Very Good Polish</td>
                    <td>Good Polish</td>
                    <td>Fair Polish</td>
                  </tr>
                </table>
              </div>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Symmetry</h2>
            <p>
              Symmetry in a diamond refers to the precise arrangement of its
              facets, ensuring each one is perfectly aligned with its
              counterpart. Inconsistent symmetry can cause light to scatter
              incorrectly, reducing the diamond’s brilliance and overall
              appearance.
            </p>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-100 text-center">
                  <tr className="w-100">
                    <td width={"25%"}>EX</td>
                    <td width={"25%"}>VG</td>
                    <td width={"25%"}>GD</td>
                    <td width={"25%"}>F</td>
                  </tr>
                  <tr className="font-normal">
                    <td>Excellent Symmetry</td>
                    <td>Very Good Symmetry</td>
                    <td>Good Symmetry</td>
                    <td>Fair Symmetry</td>
                  </tr>
                </table>
              </div>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Fluorescence</h2>
            <p>
              Diamond fluorescence is the visible light emmited by a diamond when
              exposed to ultraviolet (UV) light, typically resulting in a blue
              glow. While it doesn't affect a diamond's structure, fluorescence
              can influence its appearance, somemes enhancing or dulling its
              visual appeal depending on its strength
            </p>
            <Row className="base-grid d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-nowrap overflow-x-auto">
              <Col className="w-100 text-center" md={2}>
                <img src={none} alt="" width={100} className="mb-2" />
              </Col>
              <Col className="w-100 text-center" md={2}>
                <img src={faint} alt="" width={100} className="mb-2" />
              </Col>
              <Col className="w-100 text-center" md={2}>
                <img src={medium} alt="" width={100} className="mb-2" />
              </Col>
              <Col className="w-100 text-center" md={2}>
                <img src={strong} alt="" width={100} className="mb-2" />
              </Col>
              <Col className="w-100 text-center" md={2}>
                <img src={veryStrong} alt="" width={100} className="mb-2" />
              </Col>
            </Row>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-100 text-center">
                  <thead>
                    <tr className="w-100">
                      <th width="20%">NON</th>
                      <th width="20%">FNT</th>
                      <th width="20%">MED</th>
                      <th width="20%">STG</th>
                      <th width="20%">VST</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-normal">
                      <td>None</td>
                      <td>Faint</td>
                      <td>Medium</td>
                      <td>Strong</td>
                      <td>Very Strong</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Heart & Arrow</h2>
            <p>
              Heart & Arrows diamonds are precision-cut stones that exhibit a
              symmetrical pattern, with hearts visible from the bottom and arrows
              from the top. This high level of craftsmanship enhances the
              diamond's brilliance and visual appeal.
            </p>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-100 text-center">
                  <tr className="w-100">
                    <td width={"25%"}>Excellent</td>
                    <td width={"25%"}>Very Good</td>
                    <td width={"25%"}>Good</td>
                    <td width={"25%"}>None</td>
                  </tr>
                </table>
              </div>
            </Row>
          </div>
          <h2 className="p-5">“We go beyond common grading standards, considering additional factors for a more precise diamond evaluation.”</h2>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Luster</h2>
            <p>
              Diamond Luster refers to the way light interacts with the diamond's surface, creating a brilliant, reflective glow.
            </p>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-100 text-center">
                  <tr className="w-100">
                    <td width={"25%"}>EX</td>
                    <td width={"25%"}>VG</td>
                    <td width={"25%"}>GD</td>
                  </tr>
                  <tr className="font-normal">
                    <td>Excellent</td>
                    <td>Very Good</td>
                    <td>Good</td>
                  </tr>
                </table>
              </div>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Shade</h2>
            <p>
              Diamond shade refers to the subtle undertones or secondary hues present in a diamond that can influence its overall appearance and brilliance. Shade is an additional factor that affects how a diamond looks in different lighting conditions.
            </p>
            <p>
              Diamonds may exhibit <strong>color-less, gray, brown, green, yellow, blue or pink</strong> shades even if they appear near-colorlesson paper. It can either enhance or diminish the diamond's beauty and value.
            </p>
            <table border="1" className="w-100 text-center">
              <thead>
                <tr className="w-100">
                  <th width="20%">NON</th>
                  <th width="20%">GREEN</th>
                  <th width="20%">YELLOW</th>
                  <th width="20%">PINK</th>
                  <th width="20%">BLUE</th>
                </tr>
                <tr className="w-100">
                  <th width="20%">BROWN</th>
                  <th width="20%">GREY</th>
                  <th width="20%">YELLOW - BROWN</th>
                  <th width="20%">YELLOW - GREEN</th>
                  <th width="20%">MIXED</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Extra Facet</h2>
            <p>
              An extra facet in a diamond refers to an addional, unplanned facet that is not part of the standard faceng pattern.These facets are typically added to remove minor surface imperfecons or inclusions but do not follow the
              diamond’s original symmetrical design.
            </p>
            <p>
              They are usually small and placed near the <strong>girdle, pavilion, or culet.</strong> When positioned strategically, they do not
              significantly impact a diamond’s brilliance.
            </p>
            <table border="1" className="w-100 mx-auto text-center">
              <tr className="w-100">
                <td>SM</td>
                <td>MD</td>
                <td>HV</td>
                <td>NONE</td> 
              </tr>
              <tr className="w-100 font-normal">
                <td>Small</td>
                <td>Medium</td>
                <td>Heavy</td>
                <td>None</td>
              </tr>
            </table>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Eye Clean</h2>
            <p>
              An eye-clean diamond is one that has no visible inclusions or blemishes when viewed with the naked eye from a
              standard viewing distance (typically 6–12 inches). These diamonds may still have inclusions, but they are too small
              or positioned in a way that makes them unnoticeable without magnification.
            </p>
            <p>
              Eye-cleanliness depends on <strong>clarity grade, inclusion type & size, placement , and diamond shape & cut.</strong>
            </p>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">Inclusions</h2>
            <p>
              Diamond Inclusions are internal or external imperfections formed during a diamond's natural creation process. Their type (e.g., feather, cloud, crystal) and position (e.g., center, edge, surface) significantly impact the diamond's clarity, brilliance, and durability. Inclusions near the center are more visible and affect light reflection, while those on edges may pose a risk during cutting or setting.
            </p>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">
              White Inclusion
            </h2>
            <p className="mb-4">
              A <strong>white inclusion</strong> in a diamond refers to an internal feature or imperfection that appears white, cloudy, or milky.
              These inclusions are typically caused by t<strong>rapped minerals, uncrystallized carbon, or structural irregularities</strong> that
              occur during the diamond’s natural formation process. Unlike black inclusions
              (which are often graphite or carbon spots), white inclusions stand to be less visible but can still affect the
              diamond’s transparency and brilliance.
            </p>
            <p className="mb-4">At Arjiv’s grading we consider some of following white inclusions</p>
            <ul className="pl-3">
              {whiteInclusionDetail && whiteInclusionDetail.map((x, index) => {
                return (
                  <li className="d-block mb-1" key={index}><strong>{x.type}{' '}</strong>{x.detail}</li>
                )
              })}
            </ul>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">
              Black Inclusion
            </h2>
            <p className="mb-4">
              A <strong>black inclusion</strong> in a diamond is an internal flaw or impurity that appears as a dark or black spot inside the stone.
              These inclusions are typically made up of <strong>trapped carbon, graphite, or other minerals</strong> that did not crystallize
              properly during the diamond's natural formation. Black inclusions are often more <strong>visible to the naked eye,</strong> affecting
              the diamond’s clarity and overall appearance.
            </p>
            <p className="mb-4">At Arjiv’s grading we consider some of following black inclusions</p>
            <ul className="pl-3">
              {blackInclusionDetail && blackInclusionDetail.map((x, index) => {
                return (
                  <li className="d-block mb-1" key={index}><strong>{x.type}{' '}</strong>{x.detail}</li>
                )
              })}
            </ul>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <h2 className="d-flex align-items-center g-2">
              Black and White Inclusion both has sub part: 
            </h2>
            <p className="m-0">
              <strong>Table Inclusion:</strong> A flaw located in the center of the diamond's top facet (table), making it more visible and directly impacting brilliance.
            </p>
            <p className="mb-4">
              <strong>Side Inclusion:</strong> A flaw positioned near the edges or girdle of the diamond, often less noticeable and can be hidden by the setting.
            </p>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              <div className="overflow-auto w-100">
                <table border="1" className="w-50 mx-auto text-center mb-4">
                  <tr className="w-100">
                    <td>Table White Inclusion</td>
                    <td>Side White Inclusion</td>
                  </tr>
                  <tr>
                    <td>Table Black Inclusion</td>
                    <td>Side Black Inclusion</td>
                  </tr>
                </table>
                <p className="mb-2">Their Criteria be:</p>
                <table border="1" className="w-100 mx-auto text-center">
                  <tr className="w-100">
                    <td>None</td>
                    <td>ML</td>
                    <td>SM</td>
                    <td>MD</td>
                    <td>LRG</td>
                  </tr>
                  <tr className="w-100 font-normal">
                    <td>None</td>
                    <td>Meaningless</td>
                    <td>Small</td>
                    <td>Medium</td>
                    <td>Large</td>
                  </tr>
                </table>
              </div>
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4 border-0">
            <h2 className="d-flex align-items-center g-2">
              Open Inclusion
            </h2>
            <p className="mb-2">
              Open Inclusion in a diamond refers to an internal flaw that breaks through the surface, creating an exposed cavity or fracture.
            </p>
            <table border="1" className="w-100 text-center mb-4">
              <tr>
                <td width={'20%'}>None</td>
                <td width={'20%'}>Hair line Open inclusion</td>
                <td width={'20%'}>Small Open inclusion</td>
                <td width={'20%'}>Medium Open inclusion</td>
                <td width={'20%'}>Large Open inclusion</td>
              </tr>
            </table>
            <p className="mb-2">Open inclusion has been separated by:</p>
            <table border="1" className="w-100 text-center">
              <tr>
                <td width={'20%'}>Open on Table</td>
                <td width={'20%'}>Open on Crown</td>
                <td width={'20%'}>Open on Pavilion</td>
                <td width={'20%'}>Open on Girdle</td>
              </tr>
            </table>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4 pb-0">
            <h2 className="d-flex align-items-center justify-content-center g-2">
              Common Inclusions
            </h2>
            <Row className="d-flex align-items-start justify-content-between w-100 mt-lg-5 mt-4 flex-wrap">
              {inclusions &&
                inclusions.map((x, i) => {
                  return (
                    <Col key={i} xl={4} md={6} sm={12}
                      className="d-flex align-items-start justify-content-center mb-lg-5 mb-3 mt-3 py-lg-4 py-2 overflow-hidden"
                    >
                      <div className="text-center w-100">
                        <img className="rounded-circle" src={x.OrgImg} alt={x.name} width={100} />
                        <h5 className="mt-3">{x.name}</h5>
                      </div>

                      <div className="w-100">
                        <img src={x.mainImg} alt={x.name} width={180} />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div className="grading-details text-left mx-2 my-3 mx-lg-5 mx-md-4">
            <p className="text-center w-75 mx-auto">
              By combining standard grading with our advanced criteria, we ensure a more accurate and comprehensive
              diamond evaluation, helping you make the best choice with confidence.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GradingSystem;
