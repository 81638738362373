import React from "react";
import { Row } from "reactstrap";
import PAIR_CALIBRATED from "Assets/Image/common/PAIR_CALIBRATED.png";

const LayoutAndMatchPair = () => {
  return (
    <section className="layout-matchPair py-0 px-0">
      <div className="text-center mx-0">
        <Row className="align-items-center">
          <img
            className="h-100"
            src={PAIR_CALIBRATED}
            alt="Perfectly Paired & Precisely Calibrated"
            data-aos="fade"
            data-aos-delay="300"
          />
        </Row>
      </div>
    </section>
  );
};

export default LayoutAndMatchPair;
