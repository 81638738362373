import React from "react";
import Slider from "react-slick";

import mineSource from "Assets/Image/Arjiv/mine-source.jpg";
import building from "Assets/Image/Arjiv/building.jpg";
import Employee from "Assets/Image/Arjiv/Employee.jpg";
import grading from "Assets/Image/Arjiv/grading.jpg";

// import ShapeParticles from "Components/ShapeParticles";

import "../Theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Dashboard = () => {
  const sliderData = [
    {
      src: mineSource,
      title: "Sustainable, Ethical & Traceable Sourcing",
      caption:
        "Responsibly sourced diamonds, ensuring transparency, traceability, and ethical practices from mine to masterpiece.",
    },
    {
      src: building,
      title: "Green Building & Sustainable Excellence",
      caption:
        "Our green-certified facility reflects our commitment to sustainability and environmental responsibility.",
    },
    {
      src: Employee,
      title: "Precision, Expertise & Dedication",
      caption:
        "Skilled artisans and cutting-edge technology come together to craft brilliance with perfection.",
    },
    {
      src: grading,
      title: "Beyond Traditional Grading System",
      caption:
        "Along with standard 4Cs, we apply our own advanced criteria to ensure the highest brilliance, symmetry, and light performance.",
    },
  ];

  const sliderSettings = {
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    arrows: false,
    dots: true,
    pauseOnHover: false,
    draggable: false,
    cssEase: "linear",
  };

  const slidesList = sliderData.map((x) => {
    return (
      <div
        key={x.src}
        className="banner-details d-flex align-items-center justify-content-center position-relative flex-column"
      >
        <div className="banner-details-content w-100 h-100 position-relative">
          <img
            src={x.src}
            alt={x.caption}
            className={`${x.className} w-100 h-100`}
          />
          <div className="container-fluid banner-info">
            <h1>{x.title}</h1>
            <p>{x.caption}</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <main className="main-content pt-0 position-relative h-100">
      {/* Main Banner Section */}
      <section className="main-banners position-relative">
        {/* <ShapeParticles /> */}
        <Slider {...sliderSettings}>{slidesList}</Slider>
      </section>
    </main>
  );
};

export default Dashboard;
