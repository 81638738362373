import React from "react";
import { Row } from "reactstrap";

const Quote = () => {
  return (
    <section class="darken-bg">
      <div class="container">
        <Row className="align-items-center justify-content-center">
          <div class="testimonial-item text-center">
            <div class="testimonial-content" data-aos="fade" data-aos-delay="500">
              <p class="">
                <span class="quote-icon mr-2"></span>
                In the art of diamond polishing, we don’t just shape stones we
                shape brilliance.
              </p>
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default Quote;
