import React, { useEffect } from "react";
import Footer from "Components/footer";
import HeaderPart from "Components/header";
import Cookie from "Components/Cookie";
// import Particles from "react-particles-js";
import ScrollUpButton from "react-scroll-up-button";
import { IoIosArrowDropup } from "react-icons/io";
import AOS from "aos";

import 'bootstrap/dist/css/bootstrap.min.css';
import "aos/dist/aos.css";
import "./Components/style.css";
import "./App.css";

const App = () => {

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const headerElement = document.querySelector('.nav-1.header'); // Select the element by class name

      if (headerElement) {
        if (scrollTop > 50) {
          // Add class when scrolling down
          headerElement.classList.add('fixed-header');
        } else {
          // Remove class when scrolling back up
          headerElement.classList.remove('fixed-header');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener when component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);

  return (
    <div>
      <Cookie />
        <ScrollUpButton
          ContainerClassName="backToTop d-flex align-items-center justify-content-center pointer"
          TransitionClassName="backTopTra"
        >
          <IoIosArrowDropup className="w-100" width={40} />
        </ScrollUpButton>
      <HeaderPart />
      <Footer />
    </div>
  );
};

export default App;
