import React from "react";
import { Container, Row, Col } from "reactstrap";

const NotFound = () => {
  return (
    <>
      <main className="main-content pt-0">
        <section className="notfound d-flex align-items-center justify-content-center">
          <Container className="text-center py-5">
            <Row className="">
              <Col>
                <h1>404</h1>
                <h2>Page Not Found!</h2>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default NotFound;
