import React from "react";
import { Container, Row } from "reactstrap";

import "./bodysection.css";

function Provenance() {
  return (
    <div className="main-content">
      <Container className="main-content policy text-center pt-4">
        <h1 className="text-center pt-3">Provenance Claim Policy Statement</h1>
        <Row className="col-md-12 mt-4">
          <h4 className="text-left my-lg-4 my-3">
            The policies relating to this section are part of the Business
            Principles adopted by Arjiv Exports are presented below for
            reference:
          </h4>
          <hr/>
          <p className="text-justify">
            a. “The company shall ensure all its Provenance Claims made will be
            fully valid along with available evidence to support the Claim(s)”
          </p>
          <p className="text-justify">
            <b>Origin</b> - Geographical origin of material, for example
            country, region, mine or corporate ownership of the Mining
            Facility/ies; and/or{" "}
          </p>
          <p className="text-justify">
            <b>Source</b> - Type of source, for example recycled, mined,
            artisanally mined, or date of production; and/or{" "}
          </p>
          <p className="text-justify">
            <b>Practices</b> - Specific practices applied in the supply chain
            relevant to the Code of Practices, including but not limited to,
            standards applicable to extraction, processing or manufacturing,
            conflict-free status, or due diligence towards sources.
          </p>
          <p className="text-justify">
            Provenance Claims may also relate to origin, sources or practices
            that are specifically excluded from the supply chain, such as
            through a ‘negative warranty’.
          </p>
          <p className="text-justify">
            b. The company shall ensure that all claims and statements (made to
            consumers or other businesses) on practices in the supply chain and
            the origin or source of Diamonds use of both descriptions and
            symbols are valid.
          </p>
          <p className="text-justify">
            c. The company shall ensure that there will be no attempt to mislead
            consumers through illustrations, descriptions, expressions, words,
            figures, depictions or symbols relating to the Provenance Claim(s).
          </p>
          <p className="text-justify">
            d. The company shall ensure that all the relevant employees are
            aware about the provenance claim and it procedures.
          </p>
          
          <h2 className="text-start pt-3">GRIEVANCE MECHANISM:</h2>
          <p className="text-justify">
            e. Arjiv Exports has established this grievance procedure to hear
            concerns about circumstances in the provenance claim and sourcing
            from conflict-affected areas. The compliance officer is responsible
            for implementing and reviewing this procedure. Provenance claim
            related concerns can be raised by interested parties via email to&nbsp;
            <a className="" style={{textDecoration: 'underline'}} href="mailto:surat@arjiv.com">surat@arjiv.com</a>
          </p>
          <h4 className="my-lg-4 my-md-3 my-2">Place: India Date 01/04/2024</h4>
        </Row>
      </Container>
    </div>
  );
}

export default Provenance;
