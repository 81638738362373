import React from "react";
import { Col, Row } from "reactstrap";

import Quote from "Components/Quote";
import RJCBanner from "Components/RJCBanner";
import Customization from "Components/Customization";
import OriginNAlliance from "Components/OriginNAlliances";

// import LiveShows from "Components/LiveEvents";
import Dashboard from "Components/pages/Dashboard";
import LayoutAndMatchPair from "Components/pages/LayoutAndMatchPair";

import stepcut from "Assets/Image/ShapeCut/step-cut.jpg";
import exquiste from "Assets/Image/ShapeCut/exquiste.jpg";
import brilliant from "Assets/Image/ShapeCut/brilliant.jpg";
import fancycolor from "Assets/Image/ShapeCut/fancy-color.jpg";
import europenrose from "Assets/Image/ShapeCut/europen-rose.jpg";

import "./bodysection.css";
import "reactjs-popup/dist/index.css";

const Home = () => {
  return (
    <div className="body">
      <Dashboard />
      <RJCBanner />
      <div>
        <div className="imagesection dark">
          <Row className="image-box align-items-center flex-lg-row-reverse flex-md-column-reverse flex-column-reverse">
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <div className="col-lg-8 col-sm-12 mx-auto">
                <div className="my-lg-4 my-md-3 my-2">
                  <h3 className="pb-3 text-uppercase fs-2">Brilliant cut</h3>
                </div>
                <p className="fs-5">
                  A brilliant cut is simply a diamond or any other gemstone cut
                  in a particular form with numerous facets so to ensure
                  exceptional brilliance.
                </p>
                <a
                  target="_blank"
                  href="https://trade.arjivexports.com/"
                  className="btn btn btn-secondary mt-3"
                >
                  Discover
                </a>
              </div>
            </Col>
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <img
                src={brilliant}
                alt="Brilliant cut diamonds"
                className="w-100"
              ></img>
            </Col>
          </Row>
          <Row className="image-box align-items-center flex-lg-row flex-md-column-reverse flex-column-reverse">
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <div className="col-lg-8 col-sm-12 mx-auto">
                <div className="my-lg-4 my-md-3 my-2">
                  <h3 className="pb-3 text-uppercase fs-2">step cut</h3>
                </div>
                <p className="fs-5">
                  Stones whose outlines are either square or rectangular and
                  whose facets are rectilinear and arranged parallel to the
                  griddle known as step-or-step cut stones.
                </p>
                <a
                  target="_blank"
                  href="https://trade.arjivexports.com/"
                  className="btn btn btn-secondary mt-3"
                >
                  Discover
                </a>
              </div>
            </Col>
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <img
                src={stepcut}
                alt="Step cut diamonds"
                className="w-100"
              ></img>
            </Col>
          </Row>
          <Row className="image-box align-items-center flex-lg-row-reverse flex-md-column-reverse flex-column-reverse">
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <div className="col-lg-8 col-sm-12 mx-auto">
                <div className="my-lg-4 my-md-3 my-2">
                  <h3 className="pb-3 text-uppercase fs-2">
                    OLD CUT/ ROSE CUT/ PORTRAIT CUT
                  </h3>
                </div>
                <p className="fs-5">
                  These are one of the oldest cutting style for diamonds. Modern
                  jewellery designers are creating Contemperary jewellery with
                  an interesting old world vibe or by adding rose cut.
                </p>
                <a
                  target="_blank"
                  href="https://trade.arjivexports.com/"
                  className="btn btn btn-secondary mt-3"
                >
                  Discover
                </a>
              </div>
            </Col>
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <img
                src={europenrose}
                alt="EUROPEAN / ROSE / PORTRAIT CUT Diamonds"
                className="w-100"
              ></img>
            </Col>
          </Row>
          <Row className="image-box align-items-center flex-lg-row flex-md-column-reverse flex-column-reverse">
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <div className="col-lg-8 col-sm-12 mx-auto">
                <div className="my-lg-4 my-md-3 my-2">
                  <h3 className="pb-3 text-uppercase fs-2">EXQUISITE</h3>
                </div>
                <p className="fs-5">
                  ARJIV EXPORTS is the home to the world's most exquisite
                  diamonds that continue to underline the legacy.
                </p>
                <a
                  target="_blank"
                  href="https://trade.arjivexports.com/"
                  className="btn btn btn-secondary mt-3"
                >
                  Discover
                </a>
              </div>
            </Col>
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <img
                src={exquiste}
                alt="EXQUISITE cut diamonds"
                className="w-100"
              ></img>
            </Col>
          </Row>
          <Row className="image-box align-items-center flex-lg-row-reverse flex-md-column-reverse flex-column-reverse">
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <div className="col-lg-8 col-sm-12 mx-auto">
                <div className="my-lg-4 my-md-3 my-2">
                  <h3 className="pb-3 text-uppercase fs-2">FANCY COLOR</h3>
                </div>
                <p className="fs-5">
                  Natural Fancy colored diamonds are as rare as they exquisite.
                  Arjiv exports carries a variety of fancy colours in a range of
                  shapes, sizes colours and clarity.
                </p>
                <a
                  target="_blank"
                  href="https://trade.arjivexports.com/"
                  className="btn btn btn-secondary mt-3"
                >
                  Discover
                </a>
              </div>
            </Col>
            <Col lg={6} sm={12} className="image-box-details p-lg-0 p-3">
              <img
                src={fancycolor}
                alt="Fancy Color diamonds"
                className="w-100"
              ></img>
            </Col>
          </Row>
        </div>
      </div>
      <LayoutAndMatchPair />
      <Quote />
      <Customization />
      <OriginNAlliance />
      {/* <LiveShows title={'Exhibitions'} /> */}
    </div>
  );
};

export default Home;
